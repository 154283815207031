  // 進場動畫
  // var percent=0;
  // var timer=setInterval(function(){
  //   $(".bar").css("width",percent+"%")
  //   percent+=1
  //   if(percent>100){
  //     clearInterval(timer)
  //     $(".pageloading").addClass("compelet")
  //   }
  // },30);

// Hash & Scrolling
var scrolling = false;

var addFile_template = '';

function getSwiper02Setting() {
  var wh = viewport();
  if(wh['width'] > 1280) {
    return { items:5, distance: 180 };
  } else if(wh['width'] > 768) {
    return { items:5, distance: 125 }
  } else if(wh['width'] > 320) {
    return { items:3, distance: 100 }
  }
}

function viewport() {
  var e = window, a = 'inner';
  if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
  }
  return { width : parseInt(e[ a+'Width' ], 10) , height : parseInt(e[ a+'Height' ], 10) };
}

function goHash(target, addHeight, callback) {
  target = target.replace('_', '').replace('#', '');
  $('html, body').animate({
      scrollTop: $('#' + target).offset().top - addHeight
  }, 800, function() {
      if (callback && typeof(callback) == "function") {
          callback();
      }
  });
}

function linkHashFun() {
  var addHeight = 150;
  $(window).on('scroll',function() {
      var scrollVal = $(this).scrollTop();
      $(".headingList-wrap a[href^='#']").each(function(i, e) {
          var ths = $(e);
          var section = $(ths.attr('href').replace('_', ''));
          var scrollTop = scrollVal + addHeight + 1;
          if (!scrolling) {
              if (scrollTop >= section.offset().top && scrollTop <= (section.offset().top + section.height())) {
                  ths.addClass('active');
              } else {
                  ths.removeClass('active');
              }
          }
      });
  });

  $(".scrollLink").on('click', function(event) {
      if (this.hash !== "") {
          var ths = $(this);
          var hash = this.hash;
          scrolling = true;

          $('.scrollLink').removeClass('active');
          ths.addClass('active');
          goHash(hash, addHeight, function() {
              scrolling = false;
          });
          return false;
      }

  });

  if (window.location.hash) {
      setTimeout(function() {
          window.scrollTo(0, 0);
          goHash(window.location.hash, addHeight);
      }, 1);

  }
  $(window).bind('hashchange', function() {
      goHash(window.location.hash, addHeight);
  });
}
  
$(function() {

  linkHashFun();

  $('.btnScroll').on('click',function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var $target = $(this.hash);
      $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
      if ($target.length) {
        var targetOffset = $target.offset().top - 75;
        $('html,body').animate({ scrollTop: targetOffset }, 1000);
        return false;
      }
    }
  });

  // header
  $('.navbar-fostrapClick').on('click',function(){
    $('.nav-fostrap').toggleClass('visible');
    $('.nav-overlay').toggleClass('show');
  });

  $('.mobile-closeBtn').on('click',function(){
    $('.nav-fostrap').removeClass('visible');
    $('.nav-overlay').removeClass('show');
  })

  // Mobile arrow-down
  $('.mobile-menuBtn').on('click',function(){
    var serToggle =  $(this).closest('li').find('.dropdown');
    $(".dropdown").not(serToggle).removeClass('active');
    $('.mobile-menuBtn').not($(this)).removeClass('active');

    if($(this).hasClass('active')){
      $(this).removeClass('active');
      serToggle.removeClass('active');
    }else{
      $(this).addClass('active');
      serToggle.addClass('active');
    }
  });

  $('#js-nav-fostrap').on('mouseenter',function(){
    $('.nav-fostrap-menuBg').addClass('active')
  }).on('mouseleave',function(){
    $('.nav-fostrap-menuBg').removeClass('active')
  });
  
  // top
    $(".float-link .link-btn.top").on('click',function () {
      $("html,body").animate({ "scrollTop": "0" })
    })
    
    $(window).on("load resize scroll", function (e) {
      var scroll = $(this).scrollTop();
      // var wdh = $(window).height();
      if (scroll > 50) {
          $(".float-link").addClass("active");
          $('.nav-header').removeClass(' nav-header-index').css('z-index','1003')
      } else {
          $(".float-link").removeClass("active");
          $('.nav-header').addClass(' nav-header-index')
      }
    });

  // Datepick
  if (/Mobi/.test(navigator.userAgent)) {
    // if mobile device, use native pickers
    $(".date input").attr("type", "date");
    $(".time input").attr("type", "time");
  } else {
    // if desktop device, use DateTimePicker
    $("#datepicker").datetimepicker({
      useCurrent: false,
      format: "L",
      showTodayButton: false,
      icons: {
        next: "fa fa-chevron-right",
        previous: "fa fa-chevron-left",
        today: 'todayText',
      }
    });
  }

  // 地址套件
  $("#twzipcode").twzipcode();

  // 付款回報單的付款項目
  $('input[type=radio][name="orderItem"]').on('change', function() {
    switch($(this).val()) {
      case 'orderItem-finalPayment':
        $("#js-finalPayment").removeClass('d-none')
        break
      case 'orderItem-deposit':
        $("#js-finalPayment").addClass('d-none')
        break
    }      
  });

  $('#orderPayment').on('change',function(){
    // 預設增加一個獨立的class且先隱藏
    $('.js-orderPayment-info').addClass('d-none');

    // 抓取當前的對應id
    $("#"+$(this).find('option:selected').data('bid')).removeClass('d-none');     
  });

  // Index Banner
    var swiperIndex = new Swiper('.mySwiper .swiper-container', {
      // autoplay: true,
      delay:1000,
      effect: "fade",
      pagination: {
        el: '.mySwiper .swiper-pagination',
        clickable :true,
      },
    });

  // 合作夥伴的History swiper
    var swiper = new Swiper('.partner-timeLine-Wrap .swiper-container', {
      autoHeight: true,
      speed: 2000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      navigation: {
        nextEl: '.partner-timeLine-Wrap .swiper-button-next',
        prevEl: '.partner-timeLine-Wrap .swiper-button-prev',
      },
    });
  
  // 合作夥伴的作品集 swiper
    var swiper02 = new Swiper(".partnerCertify .swiper-container", {
      watchSlidesProgress: true,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      
      speed: 1000,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },

      navigation: {
        nextEl: ".partnerCertify .swiper-button-next",
        prevEl: ".partnerCertify .swiper-button-prev"
      },

      pagination: {
        el: ".partnerCertify .swiper-pagination",
        clickable :true,
      },
      
      on: {
        progress: function(progress) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            var slideProgress = this.slides[i].progress;
            
            var modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }

            var curSetting = getSwiper02Setting();
            var translate = slideProgress * modify * curSetting.distance + "px";
            var scale = 1 - Math.abs(slideProgress) / curSetting.items;
            var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform("translateX(" + translate + ") scale(" + scale + ")");
            slide.css("zIndex", zIndex);
            slide.css("opacity", 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css("opacity", 0);
            }
          }
        },
        setTransition: function(transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              slide.transition(transition);
            }
          }
        },
    });

  // 產品詳細頁 swiper
    var galleryThumbs = new Swiper('.prodWrap_img .gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: 3,
      direction: "vertical",
      loop: true,
      freeMode: true,
      loopedSlides: 3, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      breakpoints: { 
        1100: {
          direction: "horizontal",
          slidesPerView: 4,
          spaceBetween: 20,
        },
        
        640: {
          direction: "horizontal",
          slidesPerView: 3,
          spaceBetween: 10,
        }
      },
      navigation: {
        nextEl: '.prodWrap_img .swiper-button-next',
        prevEl: '.prodWrap_img .swiper-button-prev',
      },
    });
    var galleryTop = new Swiper('.prodWrap_img .gallery-top', {
      direction: "horizontal",
      spaceBetween: 10,
      loop:true,
      loopedSlides: 5, //looped slides should be the same
      thumbs: {
        swiper: galleryThumbs,
      },
    });

  // End

  // 新增上傳檔案區塊
  addFile_template =  '<div class="col-12 col-lg-12 form-group js-uploadItem">' + $(".js-uploadItem").eq(0).html() + '</div>';
  $('.js-addFile').on('click',function(){
    $(".js-uploadItem:last").after(addFile_template).css('margin-bottom','15px');
    $(".addFile_num:last").html($(".addFile_num").length);
  });

  $(".uploadForm").on("change", ".file-upload-field", function(){ 
    $(this).parent(".file-upload-wrapper").attr("data-text",$(this).val().replace(/.*(\/|\\)/, '') );
  });

  $(".js-firstBtn").on('click', function(){
    var ths = $(this),
        btn = ths.parent('li'),
        menu = ths.siblings('.first_menu');

    if(btn.hasClass('active')){
        btn.removeClass('active');
        menu.removeClass('active');
    }else{
        $(".js-firstBtn").parent('li').removeClass('active');
        $(".js-firstBtn").siblings('.first_menu').removeClass('active');
        btn.addClass('active');
        menu.addClass('active');
    }
  });

  $('.pro_sideMobilebtn').on('click',function(){
    $('.pro_sidemenu_ul').toggleClass('active')
  })

});



// <div class="file-select">
//   <div class="file-select-button" id="fileName">選擇檔案</div>
//   <div class="file-select-name noFile">【没有選擇檔案】</div> 
//   <input type="file" name="uploadFile" class="uploadFile">
// </div>
// $('.uploadFile').on('change', function () {
//   var filename = $(".uploadFile").val();
//   if (/^\s*$/.test(filename)) {
//     $(".file-upload").removeClass('active');
//     $(".noFile").text("No file chosen..."); 
//   }
//   else {
//     $(".file-upload").addClass('active');
//     $(".noFile").text(filename.replace("C:\\fakepath\\", "")); 
//   }
// });